import React from "react";
import {
  CartBottomDivider,
  CartDeleteBtnProduct,
  CartGlobalContainer,
  CartListContainer,
  CartProductImg,
  CartProductPrice,
  CartProductTotalPrice,
  CartQuantityOption,
  CartQuantyText,
  CartFirstInfoContainer,
  CartProductName,
  CartProductNameContainer,
  CartInfoContainer,
  CartproductQuantityContainer,
  CartListContainers,
  CartProductInfoContainer,
  CartInfoContainers,
} from "./ModalCartAdd.element";
import { useDispatch } from "react-redux";
import {
  decrementQuantity,
  removeFromCart,
  addToCart,
} from "../stores/shopping-cart/cartSlice";

const ModalCartAdd = ({ product, devise }) => {
  const close = require("../images/close.png");

  const userLocale = navigator.language || "en-US";
  const currencyFormatter = new Intl.NumberFormat(userLocale, {
    style: "currency",
    currency: devise,
    currencyDisplay: "symbol",
  });

  const dispatch = useDispatch();

  const incrementHandleClick = () => {
    const { uid, name, productDesc, price, image, refNum } = product;

    const totalPrice = price;

    dispatch(
      addToCart({ uid, name, productDesc, price, image, refNum, totalPrice })
    );
  };

  const decrementHandleClick = () => {
    const { uid } = product;

    dispatch(decrementQuantity(uid));
  };

  const deleteHandleClick = () => {
    const { uid } = product;

    dispatch(removeFromCart(uid));
  };

  return (
    <CartGlobalContainer>
      <CartListContainer>
        <CartFirstInfoContainer>
          <CartDeleteBtnProduct src={close} onClick={deleteHandleClick} />
          <CartProductImg src={product.image} />
          <CartProductNameContainer>
            <CartProductName>{product.name}</CartProductName>
          </CartProductNameContainer>
        </CartFirstInfoContainer>

        <CartInfoContainer>
          <CartProductPrice>
            {currencyFormatter.format(product.price)}
          </CartProductPrice>
        </CartInfoContainer>

        <CartInfoContainer>
          <CartproductQuantityContainer>
            <CartQuantityOption onClick={decrementHandleClick}>
              -
            </CartQuantityOption>
            <CartQuantyText>{product.quantity}</CartQuantyText>
            <CartQuantityOption onClick={incrementHandleClick}>
              +
            </CartQuantityOption>
          </CartproductQuantityContainer>
        </CartInfoContainer>

        <CartInfoContainer>
          <CartProductTotalPrice>
            {currencyFormatter.format(product.totalPrice)}
          </CartProductTotalPrice>
        </CartInfoContainer>
      </CartListContainer>

      <CartListContainers>
        <CartFirstInfoContainer>
          <CartDeleteBtnProduct src={close} onClick={deleteHandleClick} />
          <CartProductImg src={product.image} />
        </CartFirstInfoContainer>

        <CartProductInfoContainer>
          <CartProductNameContainer>
            <CartProductName>{product.name}</CartProductName>
          </CartProductNameContainer>

          <CartProductTotalPrice>
            {currencyFormatter.format(product.totalPrice)}
          </CartProductTotalPrice>

          <CartproductQuantityContainer>
            <CartQuantityOption onClick={decrementHandleClick}>
              -
            </CartQuantityOption>
            <CartQuantyText>{product.quantity}</CartQuantyText>
            <CartQuantityOption onClick={incrementHandleClick}>
              +
            </CartQuantityOption>
          </CartproductQuantityContainer>
        </CartProductInfoContainer>
      </CartListContainers>
      <CartBottomDivider />
    </CartGlobalContainer>
  );
};

export default ModalCartAdd;
