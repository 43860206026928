import styled from "styled-components";

export const ModalProductGlobalContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const ModalProductListContainer = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
`;

export const ModalProductFirstSectionContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  flex: 2.5;
  height: 100%;
`;

export const ModalProductOtherSectionContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  flex: 1;
  height: 100%;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ModalProductOtherSectionContainers = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  flex: 1;
  height: 100%;

  @media screen and (max-width: 768px) {
    flex: 2;
  }
`;

export const StatusContainer = styled.div`
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  margin-left: 2rem;
`;

export const ModalProductDateAndEditContainer = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const ModalProductImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 10px;
  margin-right: 1rem;
  margin-left: 2rem;
  background-color: #f1f1f1;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-right: 0.6rem;
  }
`;

export const ModalProductName = styled.h1`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin-right: 1rem;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
  color: #000000;

  @media screen and (max-width: 768px) {
    -webkit-line-clamp: 1;
    font-weight: 500;
    width: 150px;
  }
`;

export const ModalProductPrice = styled.h1`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin-left: 2rem;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;

export const ModalProductImageEdition = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer !important;
  margin-left: auto;
  margin-right: 1rem;
`;

export const ModalProductDivider = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid #f1f1f1 !important;
`;
