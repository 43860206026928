import styled from "styled-components";

export const CartEmptyContainer = styled.div`
  width: 100%;
  height: auto;
  background: #fff;
  display: flex;
  align-items: center;
  margin-top: 104px;
  margin-bottom: 50px;
  justify-content: center;
`;

export const CartGlobalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

export const CartShoppingContainer = styled.div`
  width: 100%;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  height: auto;
  margin-bottom: 150px;
`;

export const CartContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin: 0 auto;
  align-items: center;
`;

export const CartText = styled.h4`
  width: 440px;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #000000;
  margin-bottom: 60px;
  margin-top: 150px;
`;

export const CartContentContainer = styled.div`
  width: 1184px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const CartTabHeadContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 66.5px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const CartNameProductContainer = styled.div`
  height: 100%;
  flex: 2.5;
  display: flex;
  align-items: center;
`;

export const CartPriceProductContainer = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
`;

export const CartQuantityProductContainer = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
`;

export const CartTotalProductContainer = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
`;

export const CartHeadText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
`;

export const CartDivider = styled.div`
  width: 100%;
  height: 0px;
  border: 1px solid #000 !important;
`;

export const TotalAmountContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  align-items: center;

  line-height: 20px;
  text-transform: uppercase;
  color: #000000;
  @media screen and (max-width: 768px) {
    width: auto;
    height: auto;
    font-size: 20px;
    margin: auto;
  }
`;

export const CartFlexTotal = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
`;

export const FirstSection = styled.div`
  flex: 2.5;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Sections = styled.div`
  flex: 1;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const TotalAmountGlobalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 80px;
`;

export const CartTotalDivider = styled.div`
  width: 350px;
  height: 0px;
  align-self: flex-end;
  border: 1px solid #000 !important;
  margin-bottom: 35px;
  @media screen and (max-width: 768px) {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const CartContactWhatsapp = styled.div`
  background: #000000;
  border-radius: 100px;
  cursor: pointer;
  width: 220px;
  height: 63px;
  align-self: flex-end;
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const EnptyBtnLink = styled.div`
  width: 250px;
  height: 50px;
  text-decoration: none;
  cursor: pointer;
`;

export const EmptyBtnContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #000000 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
`;
