import styled from "styled-components";

export const SubTextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
`;

export const SubTextImg = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 1rem;
  display: flex;

  @media screen and (max-width: 768px) {
    width: 12px;
    height: 12px;
    margin-right: 0.8rem;
  }
`;

export const SubText = styled.h4`
  font-size: 1.2rem;
  font-style: normal;
  color: #676d7c;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
