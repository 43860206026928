import React from "react";
import {
  CheckContainers,
  CheckImages,
  CheckSubs,
  SelectMethodTexts,
  SubTypeContainers,
} from "./SubTypeModels.element";

const SubTypeModels = ({ text, sub }) => {
  const check = require("../images/coche.png");

  let color;

  if (text === "STANDARD") {
    color = "#48d26b";
  } else if (text === "VIP") {
    color = "#f55653";
  } else if (text === "GOLD") {
    color = "#f9bd18";
  } else {
    color = "#4499ec";
  }

  return (
    <SubTypeContainers color={color}>
      <SelectMethodTexts>{text}</SelectMethodTexts>
      <CheckContainers>
        <CheckSubs active={text === sub}>
          <CheckImages src={check} />
        </CheckSubs>
      </CheckContainers>
    </SubTypeContainers>
  );
};

export default SubTypeModels;
