import styled from "styled-components";

export const EmptyProductContainer = styled.div`
  margin-top: 100px;
  margin-bottom: 228px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    margin-top: 5rem;
    margin-bottom: 6rem;
  }
`;

export const EmptyImg = styled.img`
  width: 200px;
  height: 200px;
  margin-bottom: 0.3rem;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

export const EmptyProductText = styled.h1`
  width: 500px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  color: #000000;
  @media screen and (max-width: 768px) {
    width: 200px;
    font-size: 14px;
    line-height: 20px;
  }
`;
