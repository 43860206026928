import { styled } from "styled-components";
import { CgSpinner } from "react-icons/cg";

export const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const Modal = styled.div`
  background-color: white;
  border-radius: 20px;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30em;

  @media screen and (max-width: 768px) {
    width: 25em;
  }
`;

export const ModalContentContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
`;

export const ModalIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  height: 80px;
  background-color: #f55653;
  border-radius: 50%;
`;

export const ModalIcon = styled.img`
  display: flex;
  width: 40px;
  height: 40px;
  filter: invert(100%) sepia(26%) saturate(0%) hue-rotate(78deg)
    brightness(113%) contrast(101%);
`;

export const ModalForgotPasswordText = styled.span`
  font-size: 1.5rem;
  font-style: normal;
  width: 100%;
  color: #000 !important;
  font-weight: 700;
  margin-bottom: 0.8rem;
`;

export const ModalForgotPasswordDesc = styled.span`
  font-size: 0.8rem;
  font-style: normal;
  color: #646464;
  font-weight: 400;
`;

export const ForgotPasswordRegister = styled.form`
  margin-top: 1.5rem;
`;

export const ForgotConfirmButton = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 37px;
  margin-top: 1.5rem;
  color: #fff;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
  background-color: #f55653;
`;

export const ForgotSpinnerContainer = styled.span`
  margin-right: 0.5rem;
  flex-direction: column;
  display: none;
  justify-content: center;
`;

export const ForgotSpinner = styled(CgSpinner)`
  animation: spin 1s infinite linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const ReturnLoginScreen = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  align-items: center;
  margin-top: 2rem;
  cursor: pointer;
  padding: 0.8rem;
`;

export const ReturnText = styled.text`
  font-size: 1rem;
  font-style: normal;
  color: #646464;
  font-weight: 500;
  margin-left: 0.6rem;
`;

export const ReturnImage = styled.img`
  width: 20px;
  height: 20px;
`;
