import React from "react";
import useAuths from "../../hooks/useAuths";
import {
  Buton,
  Butons,
  ButtonContainer,
  ButtonLogout,
  Buttons,
  CardContainer,
  CardContainers,
  CardContent,
  Espacer,
  ProductAddContainer,
  ProductAddContentContainer,
  ProductAddTex,
  ProductAddText,
  ProductAddTexts,
  ProductIcon,
  ProductImage,
  SelectAboutText,
  SelectUserSubContainer,
  Spacer,
  SubInfoSectionContainer,
  UserMemberDesc,
  UserMemberTitle,
  UserProfilBrand,
  UserProfilContainer,
  UserProfilEmail,
  UserProfileNameContainer,
  UserProfilImageContainer,
  UserProfilImg,
  UserProfilInfoSection,
  UserProfilInfoSectionContainer,
  UserProfilName,
  UserProfilSectionContainer,
  UserProfilSubContent,
  UserProfilSubTitle,
  UserProfilSubTitleContainer,
  UserProfilSubTitleContainers,
  UserProfilSubTitles,
} from "./UserProfilePage.element";
import { useNavigate } from "react-router-dom";
import { EuiBadge } from "@elastic/eui";
import moment from "moment";
import { SubTypeData } from "../../utils/util";
import SubTypeModels from "../SubTypeModels";
import {
  STANDARDInfoData,
  GoldInfoData,
  PrenuimInfoData,
  VipInfoData,
} from "../../utils/util";
import SubModelText from "../SubModelText";
import Footer from "../Footer";
import axios from "axios";
import NavBar from "../NavBar";

const UserProfilePage = ({ db }) => {
  useAuths();
  const navigate = useNavigate();

  const [user, setUser] = React.useState();
  const [uid, setUid] = React.useState("");

  const img = require("../../images/d.jpg");
  const icon2 = require("../../images/product-2.webp");

  let button;
  let button2;

  const logout = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No token found in localStorage");
      return;
    }

    try {
      await axios.post("https://sinogani-api-server.onrender.com/auth/logout");
      // Clear the token from localStorage
      localStorage.removeItem("token");
      localStorage.removeItem("uid");
      localStorage.removeItem("email");
      // Redirect to the signin page
      navigate("/signin");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  let subStatus;

  let userInfo;

  const getUserList = async (email) => {
    try {
      const response = await axios.get(
        "https://sinogani-api-server.onrender.com/auth/user",
        {
          params: {
            email: email,
          },
        }
      );

      setUser(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching user list:", error);
      throw error;
    }
  };

  React.useEffect(() => {
    const fetchUserData = async () => {
      const uid = localStorage.getItem("uid");
      const email = localStorage.getItem("email");
      const token = localStorage.getItem("token");

      try {
        setUid(uid);
        const userData = await getUserList(email, token);
        setUser(userData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user) {
    if (user.sub) {
      if (!user.cancel) {
        if (
          user.subStatus === true &&
          moment(user.subBeginDate).isAfter(moment().format("L"))
        ) {
          subStatus = <EuiBadge color="primary">Upcoming</EuiBadge>;
          button = (
            <Butons>
              <span>Off</span>
            </Butons>
          );

          button2 = (
            <Butons>
              <span>Off</span>
            </Butons>
          );
        } else if (
          user.subStatus === true &&
          moment(user.subEndStatusDate).isAfter(moment().format("L"))
        ) {
          subStatus = <EuiBadge color="success">Active</EuiBadge>;
          button = (
            <Buton
              onClick={() =>
                user && navigate(`/user/${uid}/add-product?q=${uid}`)
              }
            >
              <span>Get Start</span>
            </Buton>
          );
          button2 = (
            <Buttons
              onClick={() =>
                user && navigate(`/user/${uid}/product-list?q=${uid}`)
              }
            >
              <span>Product List</span>
            </Buttons>
          );
        } else {
          subStatus = <EuiBadge color="default">Ended</EuiBadge>;
          button = (
            <Butons>
              <span>Off</span>
            </Butons>
          );

          button2 = (
            <Butons>
              <span>Off</span>
            </Butons>
          );
        }
      } else {
        subStatus = <EuiBadge color="danger">Cancelled</EuiBadge>;
        button = (
          <Butons>
            <span>Off</span>
          </Butons>
        );
      }
    } else {
      subStatus = <EuiBadge color="default">Off</EuiBadge>;
      button = (
        <Butons>
          <span>Off</span>
        </Butons>
      );
      button2 = (
        <Butons>
          <span>Off</span>
        </Butons>
      );
    }
  }

  if (user) {
    if (user.subType === "VIP") userInfo = VipInfoData;
    else if (user.subType === "PRENUIM") userInfo = PrenuimInfoData;
    else if (user.subType === "STANDARD") userInfo = STANDARDInfoData;
    else if (user.subType === "GOLD") userInfo = GoldInfoData;
  }

  return (
    <UserProfilContainer>
      <NavBar user={user} subStatus={subStatus} onLogClick={logout} />
      <UserProfilBrand>
        <UserMemberTitle>
          {user && `${user.subType} MEMBERSHIP`}
        </UserMemberTitle>
        <UserMemberDesc>BENEFITS</UserMemberDesc>
        <UserProfilSubTitles>Customer ID: {uid && uid}</UserProfilSubTitles>
      </UserProfilBrand>

      <UserProfilSectionContainer>
        <UserProfilInfoSectionContainer>
          <UserProfilImageContainer>
            <UserProfilImg src={img} />
          </UserProfilImageContainer>
          <UserProfilInfoSection>
            <UserProfileNameContainer>
              <UserProfilName>{user && user.userName}</UserProfilName>
              <UserProfilEmail>{user && user.email}</UserProfilEmail>
              <Espacer />
              <UserProfilSubTitleContainer>
                <UserProfilSubTitle>Date Created</UserProfilSubTitle>
              </UserProfilSubTitleContainer>
              <UserProfilSubTitleContainer>
                <UserProfilSubContent>
                  {user && user.createDate}
                </UserProfilSubContent>
              </UserProfilSubTitleContainer>
              <Spacer />
              <UserProfilSubTitleContainer>
                <UserProfilSubTitle>Begin date</UserProfilSubTitle>
                <UserProfilSubTitle>End date</UserProfilSubTitle>
              </UserProfilSubTitleContainer>
              <UserProfilSubTitleContainer>
                <UserProfilSubContent>
                  {user && user.subBeginDate}
                </UserProfilSubContent>
                <UserProfilSubContent>
                  {user && user.subEndStatusDate}
                </UserProfilSubContent>
              </UserProfilSubTitleContainer>
              <Spacer />
              <UserProfilSubTitleContainers>
                <UserProfilSubContent>Status</UserProfilSubContent>
                <UserProfilSubContent>{user && subStatus}</UserProfilSubContent>
              </UserProfilSubTitleContainers>
            </UserProfileNameContainer>
            <ButtonLogout onClick={logout}>Log Out</ButtonLogout>
          </UserProfilInfoSection>
        </UserProfilInfoSectionContainer>
      </UserProfilSectionContainer>

      <SubInfoSectionContainer>
        <SelectUserSubContainer>
          {user &&
            SubTypeData.map((item) => (
              <SubTypeModels
                sub={user.subType}
                text={item.value}
                key={item.value}
              />
            ))}
        </SelectUserSubContainer>

        <SelectAboutText>About</SelectAboutText>

        <CardContainer>
          <CardContent>
            {user &&
              userInfo.map((item) => (
                <SubModelText text={item.text} key={item.id} />
              ))}
          </CardContent>
        </CardContainer>

        <SelectAboutText>Publish Product</SelectAboutText>

        <CardContainers>
          <ProductImage>
            <ProductIcon src={icon2} />
          </ProductImage>

          <ProductAddContainer>
            <ProductAddContentContainer>
              <ProductAddText>ADD PRODUCT</ProductAddText>
              <ProductAddTexts>ADD PRODUCT FOR YOUR CUSTOMERS</ProductAddTexts>
              <ProductAddTex>
                Sinogani: Become an international seller upon membership
                confirmation. Explore a platform to expand your global presence.
              </ProductAddTex>

              <ButtonContainer>
                {button}
                {button2}
              </ButtonContainer>
            </ProductAddContentContainer>
          </ProductAddContainer>
        </CardContainers>
      </SubInfoSectionContainer>
      <Footer />
    </UserProfilContainer>
  );
};

export default UserProfilePage;
