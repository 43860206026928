import styled from "styled-components";
import { CgSpinner } from "react-icons/cg";

export const ModalPEContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const ModalPE = styled.div`
  background-color: white;
  border-radius: 20px;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45em;

  @media screen and (max-width: 768px) {
    width: 30em;
    padding: 2rem;
    height: 100%;
  }
`;

export const ModalContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeadDivPE = styled.div`
  width: 100%;
  border-radius: 20px;
  height: 80px;
  background-color: #f1f1f1;
`;

export const ModalProductImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  align-self: center;
  margin-bottom: 1.5rem;
`;

export const ModalProductImgs = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 10px;
  justify-content: center;
  margin-right: 10px;
`;

export const ProductsImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
`;

export const ProductInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ProductFirstSection = styled.div`
  width: 100%;
  flex-direction: row;
  height: 80px;
  display: flex;
`;

export const ProductFirstSections = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  height: 80px;
  display: flex;
`;

export const ButtonRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  margin-left: auto;
`;

export const CancelModalProduct = styled.div`
  display: flex;
  align-items: center;
  width: 90px;
  height: 50px;
  justify-content: center;
  border-radius: 10px;
  margin-right: 1.2rem;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 20px;
  color: #000000;
  cursor: pointer;
  border: 1.5px solid #d4d4d4 !important;
`;

export const ClickModalProduct = styled.div`
  display: flex;
  align-items: center;
  width: 120px;
  height: 40px;
  justify-content: center;
  border-radius: 10px;
  margin-left: 1rem;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 20px;
  color: #000000;
  cursor: pointer;
  border: 1.5px solid #d4d4d4 !important;
`;

export const ClickModalProducts = styled.div`
  display: flex;
  align-items: center;
  min-width: 150px;
  height: 40px;
  justify-content: center;
  border-radius: 10px;
  margin-left: 1rem;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 20px;
  color: #000000;
  cursor: pointer !important;
  border: 1.5px solid #d4d4d4 !important;
`;

export const SaveChanges = styled.div`
  display: flex;
  align-items: center;
  width: 150px;
  height: 50px;
  justify-content: center;
  border-radius: 10px;
  background-color: #202021;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 20px;
  color: #fff;
  cursor: pointer;
`;

export const DeleteProducts = styled.div`
  display: flex;
  align-items: center;
  width: 150px;
  height: 50px;
  justify-content: center;
  border-radius: 10px;
  background-color: #fbf5f5;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 20px;
  align-self: center;
  color: #b3393c;
  cursor: pointer;
`;

export const DeleteProduct = styled.div`
  display: flex;
  align-items: center;
  width: 150px;
  height: 50px;
  justify-content: center;
  border-radius: 10px;
  background-color: #202021;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 20px;
  color: #fff;
  cursor: pointer;
`;

export const ImputPriceContainer = styled.div`
  width: 100%;
  height: 50px;
  background: #ffffff;
  padding: 0 1rem;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  align-items: center;
  cursor: pointer;
  border: 1.5px solid #edeff3 !important;
  border-radius: 0.8rem;
  box-sizing: border-box;
`;

export const ProductPEtName = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 17px;
  text-transform: uppercase;
  color: #000000;
`;

export const ImputPrice = styled.input`
  width: 100%;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 400;
  outline: none !important;
  background-color: #fff;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;

export const ProductSectionLeft = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

export const ProductSectionRight = styled.div`
  display: flex;
  width: 100%;
  flex: 2;
  flex-direction: column;
  height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
`;

export const Contents = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 1.5rem;
`;

export const In = styled.div`
  height: 100%;
`;

export const ProductSectionLeftText = styled.h1`
  margin-top: 1.5rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  color: #000000;
`;

export const Productdivider = styled.div`
  width: 100%;
  border: 1px solid #ededed !important;
  height: 1px;
`;

export const DeleteSpinnerContainer = styled.span`
  margin-right: 0.5rem;
  flex-direction: column;
  display: none;
  justify-content: center;
`;

export const DeleteProductSpinner = styled(CgSpinner)`
  animation: spin 1s infinite linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
