import { styled } from "styled-components";

export const SubTypeContainers = styled.div`
  flex: 1;
  margin-right: 0.5rem;
  height: 80px;
  display: flex;
  position: relative;
  background-color: ${({ color }) => color};
  cursor: pointer;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 4px;
`;

export const SelectMethodTexts = styled.span`
  font-size: 1.2rem;
  font-style: normal;
  color: #fff;
  font-weight: 700;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const CheckContainers = styled.div`
  position: absolute;
  top: 5%;
  left: 4%;
`;

export const CheckSubs = styled.div`
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  display: ${({ active }) => (active ? "flex" : "none")};
  align-items: center;
  justify-content: center;
`;

export const CheckImages = styled.img`
  height: 20px;
  width: 20px;
  padding: 2px;
  align-self: center;
`;
