import React from "react";
import {
  CTText2,
  CountryProductConatiner,
  ProductScreenContentContainerWrapper,
  ProductLoadingContainer,
  CountryTabFeedContainer,
  ProductGridContainer,
  TabHeadTitle,
  TabSearchBar,
  TabSearchIcon,
  TabSearchInput,
  TabHeadCartContainer,
  CartLink,
  FavorisIcon,
} from "./CountryProduct.element";
import {
  AfricaProductCategories,
  ChinaProductCategories,
} from "../../data/data";
import Product from "../Product";
import Footer from "../Footer";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import ProductDetail from "./ProductDetail";
import { Badge } from "@mui/material";
import EmptyProductFeed from "../EmptyProductFeed";
import { Oval } from "react-loader-spinner";
import ModalRecommandedProduct from "../ModalRecommandedProduct";
import axios from "axios";

const CountryProduct = ({ isAfrica }) => {
  const params = new URLSearchParams(window.location.search);

  const country = params.get("q");
  const devise = params.get("d");
  const cartIcon = require("../../images/cart.png");
  const icon = require("../../images/searchs.png");

  const totalQuantity = useSelector((state) => state.cart.totalQuantity);

  const [activeGenre, setActiveGenre] = React.useState("all");
  const [loading, setLoading] = React.useState(false);
  const [productFiltered, setProductFiltered] = React.useState([]);
  const [productClick, setProductClick] = React.useState(false);
  const [product, setProduct] = React.useState(null);
  const [searchApiData, setSearchApiData] = React.useState([]);
  const [filterVal, setFilterVal] = React.useState("");
  const [modalOpen, setModalOpen] = React.useState(true);

  const closeModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleChangeState = (data) => {
    setProductClick(true);
    setProduct(data);
  };

  const handleFilter = (e) => {
    if (e.target.value === "") {
      setProductFiltered(searchApiData);
    } else {
      const filterResult = searchApiData.filter((item) =>
        item.productName.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setProductFiltered(filterResult);
    }

    setFilterVal(e.target.value);
  };

  const handleLoadProductWithoutCategoryFilters = async () => {
    let products = [];
    try {
      const response = await axios.get(
        "https://sinogani-api-server.onrender.com/products",
        {
          params: {
            isAfrica: isAfrica,
            country: country,
          },
        }
      );
      products = response.data;
      setProductFiltered(products);
      setSearchApiData(products);
    } catch (error) {
      console.error("Erreur lors de la récupération des produits :", error);
    } finally {
      setLoading(false);
    }

    return products;
  };

  const handleLoadProductWithCategoryFilter = async (filters) => {
    let products = [];
    try {
      const response = await axios.get(
        "https://sinogani-api-server.onrender.com/products/filters",
        {
          params: {
            isAfrica: isAfrica,
            country: country,
            filters: filters,
          },
        }
      );
      products = response.data;
      console.log(isAfrica);
      console.log(country);
      console.log(filters);
      console.log(products);
      setProductFiltered(products);
      setSearchApiData(products);
    } catch (error) {
      console.error("Erreur lors de la récupération des produits :", error);
    } finally {
      setLoading(false);
    }

    return products;
  };

  React.useEffect(() => {
    if (activeGenre === "all") {
      setLoading(true);
      handleLoadProductWithoutCategoryFilters();
    } else {
      setLoading(true);
      handleLoadProductWithCategoryFilter(activeGenre);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeGenre]);

  const getRandomProduct = (products) => {
    // Vérifiez si le tableau est vide
    if (products.length === 0) {
      return null;
    }

    // Générez un index aléatoire entre 0 et la longueur du tableau - 1
    const randomIndex = Math.floor(Math.random() * products.length);

    // Retournez l'élément du tableau correspondant à l'index aléatoire
    return products[randomIndex];
  };

  const size = window.innerWidth > 768 ? 100 : 50;

  return (
    <CountryProductConatiner>
      <Toaster toastOptions={{ duration: 4000 }} />

      {productFiltered.length !== 0 && modalOpen && (
        <ModalRecommandedProduct
          product={getRandomProduct(productFiltered)}
          changeState={closeModal}
          devise={devise}
        />
      )}

      <CountryTabFeedContainer>
        <TabHeadCartContainer>
          <CTText2>Browse by category</CTText2>

          <CartLink to={`/cart?q=${country}&d=${devise}`}>
            <Badge badgeContent={totalQuantity} color="error">
              <FavorisIcon src={cartIcon} />
            </Badge>
          </CartLink>
        </TabHeadCartContainer>

        <TabSearchBar>
          <TabSearchIcon src={icon} alt="search icon" />
          <TabSearchInput
            value={filterVal}
            placeholder="Search"
            onInput={(e) => handleFilter(e)}
          />
        </TabSearchBar>

        <TabHeadTitle>
          {isAfrica
            ? AfricaProductCategories.map((item) => (
                <button
                  className={activeGenre === item.type ? "active__tabs" : "tab"}
                  key={item.type}
                  onClick={() => {
                    setActiveGenre(item.type);
                    setProductClick(false);
                  }}
                >
                  {item.name}
                </button>
              ))
            : ChinaProductCategories.map((item) => (
                <button
                  className={activeGenre === item.type ? "active__tabs" : "tab"}
                  onClick={() => {
                    setActiveGenre(item.type);
                    setProductClick(false);
                  }}
                  key={item.type}
                >
                  {item.name}
                </button>
              ))}
        </TabHeadTitle>

        {!productClick ? (
          !loading ? (
            productFiltered.length !== 0 ? (
              <ProductGridContainer>
                {productFiltered.map((item, index) => (
                  <Product
                    product={item}
                    changeState={handleChangeState}
                    key={index}
                    devise={devise}
                  />
                ))}
              </ProductGridContainer>
            ) : (
              <EmptyProductFeed />
            )
          ) : (
            <ProductScreenContentContainerWrapper>
              <ProductLoadingContainer>
                <Oval height={size} width={size} color="black" />
              </ProductLoadingContainer>
            </ProductScreenContentContainerWrapper>
          )
        ) : (
          <ProductDetail
            productT={product}
            devise={devise}
            products={productFiltered}
          />
        )}
      </CountryTabFeedContainer>

      <Footer />
    </CountryProductConatiner>
  );
};

export default CountryProduct;
