import React from "react";
import {
  AddProductDescInputGroup,
  AddProductDescInputs,
  AddProductDescLabel,
} from "./AddProductDescInput.element";

const AddProductDescInput = ({ id, label, register }) => {
  return (
    <AddProductDescInputGroup>
      <AddProductDescLabel>{label}</AddProductDescLabel>
      <AddProductDescInputs cols="40" rows="8" id={id} {...register} />
    </AddProductDescInputGroup>
  );
};

export default AddProductDescInput;
