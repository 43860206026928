import React from "react";
import { IconContext } from "react-icons/lib";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa";
import {
  MobileIcons,
  Nav,
  NavButtonLogout,
  NavEspacer,
  NavLogo,
  NavMenu,
  NavSpacer,
  NavUserProfilEmail,
  NavUserProfilImg,
  NavUserProfilName,
  NavUserProfilSectionContainer,
  NavUserProfilSubContent,
  NavUserProfilSubTitle,
  NavUserProfilSubTitleContainer,
  NavUserProfilSubTitleContainers,
  NavUserProfileNameContainer,
  NavbarContainer,
  UserIcons,
} from "./NavBar.element";

const NavBar = ({ user, subStatus, onLogClick }) => {
  const [click, setClick] = React.useState(false);

  const img = require("../images/d.jpg");

  const handleClick = () => {
    setClick(!click);
  };
  return (
    <IconContext.Provider value={{ color: "#fff" }}>
      <Nav>
        <NavbarContainer onClick={handleClick}>
          <UserIcons>
            <FaRegUser />
          </UserIcons>

          <MobileIcons onClick={handleClick}>
            {click ? <AiOutlineClose /> : <BiMenuAltRight />}
          </MobileIcons>
          <NavMenu onClick={handleClick} click={click}>
            <NavUserProfilSectionContainer>
              <NavUserProfilImg src={img} />

              <NavUserProfileNameContainer>
                <NavUserProfilName>{user && user.userName}</NavUserProfilName>
                <NavUserProfilEmail>{user && user.email}</NavUserProfilEmail>
                <NavEspacer />
                <NavUserProfilSubTitleContainer>
                  <NavUserProfilSubTitle>Date Created</NavUserProfilSubTitle>
                </NavUserProfilSubTitleContainer>
                <NavUserProfilSubTitleContainer>
                  <NavUserProfilSubContent>
                    {user && user.createDate}
                  </NavUserProfilSubContent>
                </NavUserProfilSubTitleContainer>
                <NavSpacer />
                <NavUserProfilSubTitleContainer>
                  <NavUserProfilSubTitle>Begin date</NavUserProfilSubTitle>
                  <NavUserProfilSubTitle>End date</NavUserProfilSubTitle>
                </NavUserProfilSubTitleContainer>
                <NavUserProfilSubTitleContainer>
                  <NavUserProfilSubContent>
                    {user && user.subBeginDate}
                  </NavUserProfilSubContent>
                  <NavUserProfilSubContent>
                    {user && user.subEndStatusDate}
                  </NavUserProfilSubContent>
                </NavUserProfilSubTitleContainer>
                <NavSpacer />
                <NavUserProfilSubTitleContainers>
                  <NavUserProfilSubContent>Status</NavUserProfilSubContent>
                  <NavUserProfilSubContent>
                    {user && subStatus}
                  </NavUserProfilSubContent>
                </NavUserProfilSubTitleContainers>

                <NavButtonLogout onClick={onLogClick}>Log Out</NavButtonLogout>
              </NavUserProfileNameContainer>
            </NavUserProfilSectionContainer>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </IconContext.Provider>
  );
};

export default NavBar;
