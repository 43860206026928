import axios from "axios";

const useUpdateProduct = () => {
  const updateProducts = async (product, newData) => {
    const token = localStorage.getItem("token");

    const updatedProduct = {
      productName: product.productName,
      description: product.description,
      price: newData.price ? newData.price : product.price,
      minOrder: product.minOrder,
      contact: product.contact,
      category: newData.category ? newData.category : product.category,
      dateCreated: product.dateCreated,
      images: product.images,
      deleveryDate: product.deleveryDate,
      space: product.space,
      country: product.country,
      sellerUid: product.sellerUid,
      sellerMail: product.sellerMail,
      videoUrl: product.videoUrl,
      sizes: product.sizes,
      colors: product.colors,
      stock: newData ? newData.stock : product.stock,
    };

    try {
      const response = await axios.post(
        "https://sinogani-api-server.onrender.com/api/update/product",
        {
          productUid: product.uid,
          newData: updatedProduct,
        }
      );

      if (response.status == 200) {
        console.log("Succes");
      }
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  return { updateProducts };
};

export default useUpdateProduct;
