import styled from "styled-components";

export const UserProfilContainer = styled.div`
  width: 100%;
  display: block;
  overflow: hidden;
  background-color: #f7f8fc;
`;

export const UserProfilBrand = styled.div`
  width: 100%;
  height: 300px;
  background-color: #2f3a4f;
  background-image: radial-gradient(circle at bottom, #54688e, #2f3a4f 330px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    height: 200px;
    background-image: none;
  }
`;

export const UserMemberTitle = styled.h1`
  font-size: 55px;
  margin-left: 1.5rem;
  font-style: normal;
  color: #fff !important;
  font-weight: 700;
  margin-bottom: 0.5rem;
  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 2.5rem;
    margin: 0 auto;
  }
`;

export const UserMemberDesc = styled.h1`
  font-size: 35px;
  font-style: normal;
  color: #fff !important;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 1.5rem;

    margin: 1rem auto;
  }
`;

export const UserProfilSectionContainer = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 1284px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 200px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const UserProfilInfoSectionContainer = styled.div`
  width: 260px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const UserProfilImageContainer = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  z-index: 1;
  position: relative;
  margin: 0 auto;
  padding: 6px;
  background-color: #fff;
`;

export const UserProfilInfoSection = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  height: 500px;
  padding: 10px 20px;
  position: absolute;
  box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.01);
  top: 210%;
  transform: translateY(-50%);
`;

export const UserProfilImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

export const UserProfileNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4.4rem;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const UserProfilName = styled.div`
  font-size: 1.5rem;
  font-style: normal;
  color: #000 !important;
  margin-top: 2rem;
  font-weight: 700;
  margin-bottom: 0.7rem;
  justify-content: center;
  align-self: center;
`;

export const UserProfilEmail = styled.div`
  font-size: 0.9rem;
  font-style: normal;
  color: #646464;
  font-weight: 500;
`;

export const Espacer = styled.div`
  width: 100%;
  height: 5rem;
`;

export const UserProfilSubTitleContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const UserProfilSubTitleContainers = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2.5rem;
`;

export const UserProfilSubTitle = styled.div`
  flex: 1;
  display: flex;
  font-size: 1rem;
  font-style: normal;
  flex-direction: row;
  color: #727682;
  font-weight: 500;
`;

export const UserProfilSubTitles = styled.div`
  display: flex;
  font-size: 1rem;
  font-style: normal;
  margin-top: 1.5rem;
  flex-direction: row;
  color: white;
  font-weight: 500;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const UserProfilSubContent = styled.div`
  flex: 1;
  display: flex;
  font-size: 1rem;
  font-style: normal;
  flex-direction: row;
  color: #000;
  opacity: 0.8;
  font-weight: 700;
`;

export const UserProfilSubContents = styled.div`
  flex: 1;
  display: flex;
  font-size: 1rem;
  font-style: normal;
  flex-direction: row;
  color: #000;
  opacity: 0.8;
  font-weight: 700;
`;

export const Spacer = styled.div`
  width: 100%;
  height: 1.5rem;
`;

export const ButtonLogout = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 42px;
  right: 0.8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #fff;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
  background-color: #f55653;
`;

export const SubInfoSectionContainer = styled.div`
  max-width: 1184px;
  width: 55%;
  position: relative;
  top: 90%;
  left: 9%;
  background-color: #f7f8fc;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    align-items: center;
  }
`;

export const SelectUserSubContainer = styled.div`
  width: 80%;
  display: flex;
  margin: 2rem 0;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;

export const SelectAboutText = styled.span`
  font-size: 2.5rem;
  font-style: normal;
  width: 100%;
  color: #000 !important;
  margin: 1.5rem 0;
  font-weight: 500;
  opacity: 0.9;
  margin-bottom: 0.5rem;
  justify-content: center;
  align-self: center;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
    margin-left: 1.25rem;
  }
`;

export const CardContainer = styled.div`
  box-shadow: 0px 10px 20px 0 rgba(47, 58, 79, 0.15);
  width: 80%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 2rem;
  margin-top: 1rem;

  @media screen and (max-width: 768px) {
    width: 95%;
    padding: 0.6rem;
    border-radius: 8px;
  }
`;

export const CardContainers = styled.div`
  box-shadow: 0px 10px 20px 0 rgba(47, 58, 79, 0.15);
  width: 80%;
  height: 350px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 2rem;
  margin-top: 1rem;

  @media screen and (max-width: 768px) {
    width: 95%;
    flex-direction: column;
  }
`;

const back = require("../../images/bacground/back_7.jpeg");

export const ProductImage = styled.div`
  height: 100%;
  width: 40%;
  max-width: 40%;
  background-image: url(${back});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  background-position: center;

  @media screen and (max-width: 960px) {
    height: 40%;
    width: 100%;
    max-width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`;

export const ProductAddContainer = styled.div`
  height: 100%;
  width: 60%;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    height: 60%;
    width: 100%;
    max-width: 100%;
  }
`;

export const ProductAddContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    margin-top: 1.5rem;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    margin-top: 1.5rem;
  }
`;

export const Buton = styled.div`
  border-radius: 30px;
  border: 1px solid #000000 !important;
  background: transparent;
  color: #000;
  width: 120px;
  font-size: 600;
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  padding: 7px 16px;
  font-size: 1rem;
  outline: none;
  cursor: pointer;

  &:hover {
    transition: all 0.3 ease-in-out;
    background: #000;
    color: #fff;
  }
`;

export const Butons = styled.div`
  border-radius: 30px;
  border: 1px solid #000000 !important;
  background: #000;
  color: #fff;
  width: 120px;
  margin-right: 1rem;
  font-size: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 7px 16px;
  font-size: 1rem;
  outline: none;
`;

export const Buttons = styled.div`
  border-radius: 30px;
  border: 1px solid #000000 !important;
  background: #000;
  color: #fff;
  width: 120px;
  margin-right: 1rem;
  font-size: 600;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 7px 16px;
  font-size: 1rem;
  outline: none;
`;

export const ProductIcon = styled.img`
  height: 280px;
  width: 290px;
  object-fit: center;

  @media screen and (max-width: 960px) {
    height: 200px;
    width: 210px;
    object-fit: center;
  }
`;

export const ProductAddText = styled.span`
  display: flex;
  font-size: 1rem;
  width: 200px;
  font-style: normal;
  margin-bottom: 1rem;
  color: #727682;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ProductAddTex = styled.span`
  display: flex;
  font-size: 0.6rem;
  width: 200px;
  font-style: normal;
  color: #727682;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    width: 300px;
  }
`;

export const ProductAddTexts = styled.span`
  display: flex;
  font-size: 2.5rem;
  width: 300px;
  font-style: normal;
  line-height: 35px;
  margin-bottom: 1rem;
  color: #000;
  font-weight: 700;
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 25px;
  }
`;

export const CardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
