import styled from "styled-components";

export const AddProductDescInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1.2rem;

  @media screen and (max-width: 768px) {
    gap: 1rem;
  }
`;

export const AddProductDescLabel = styled.label`
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  color: #000000;
  @media screen and (max-width: 768px) {
    font-weight: 400;
    font-size: 1.2rem;
  }
`;

export const AddProductDescInputs = styled.textarea`
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 1rem;
  font-size: 0.9rem;
  outline: none;
  border: 1.5px solid #000000 !important;
  border: 1.5px solid #edeff3 !important;
  border-radius: 0.8rem;
  box-sizing: border-box;
`;
