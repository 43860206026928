import React from "react";
import {
  ButtonContainer,
  FooterContainer,
  FooterText,
  FooterTextCopyRight,
  LeftButton,
  RightButton,
} from "./Footer.element";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterTextCopyRight>SINOGANI - COPYRIGHT 2022</FooterTextCopyRight>
      <FooterText>
        Subscribe to our newsletter to be informed of our offers!
      </FooterText>
      <ButtonContainer>
        <LeftButton>About us</LeftButton>
        <RightButton>Contact us</RightButton>
      </ButtonContainer>
    </FooterContainer>
  );
};

export default Footer;
