import styled from "styled-components";
import { Container } from "../../globalStyles";
import { CgSpinner } from "react-icons/cg";

export const SignInContainer = styled.div`
  width: "100vw";
  height: "100vh";
  display: flex;
  background-color: #f1f1f1;
  z-index: 20000;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const LoginContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 15px;
  height: 88%;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
  }
  //box-shadow: -10px 10px 10px rgba(0,0,0, 0.1);

  ${Container}
`;

export const LoginRightContainer = styled.div`
  flex: 1;
  height: 100%;
  background-color: #2f3a4f;
  border-radius: 0px 15px 15px 0px;
  background-image: radial-gradient(circle at center, #54688e, #2f3a4f 430px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const LoginRightImg = styled.img`
  width: 60%;
  transform: rotate(0deg);
  z-index: 0;
`;

export const LoginLeftContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const LoginLContainer = styled.div`
  width: 380px;
  display: flex;
  height: 100%;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  margin-top: 70px;
  flex-direction: row;
`;

export const LogoImg = styled.img`
  height: 40px;
  object-fit: cover;
  margin-right: 15px;
`;

export const LogoName = styled.span`
  color: #646464 !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  justify-content: center;
  align-self: center;
`;

export const LoginText = styled.span`
  font-size: 1.5rem;
  font-style: normal;
  width: 100%;
  color: #000 !important;
  margin-top: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  justify-content: center;
  align-self: center;
`;

export const LoginDescText = styled.span`
  font-size: 0.8rem;
  font-style: normal;
  width: 100%;
  color: #646464;
  font-weight: 400;
`;

export const LoginMethodContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 2.5rem 0;
  flex-direction: row;
`;

export const LoginMethod = styled.div`
  flex: 1;
  margin-right: 1rem;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: center;
  cursor: pointer;
  flex-direction: row;
  border-radius: 8px;
  border: 2px solid #e6ecf1 !important;
`;

export const LoginMethodSecond = styled.div`
  flex: 1;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  flex-direction: row;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid #e6ecf1 !important;
`;

export const LoginMethodImg = styled.img`
  width: 25px;
  height: 25px;

  margin-right: 0.5rem;
`;

export const FormRegisterContainer = styled.form`
  margin-top: 1.9rem;
`;

export const LoginMethodText = styled.span`
  font-size: 0.8rem;
  font-style: normal;
  color: #646464;
  font-weight: 700;
`;

export const LoginBarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const LoginBar = styled.span`
  flex: 1;
  height: 2px;
  background-color: #e3e9f1;
`;

export const LoginOrText = styled.span`
  flex: 1;
  font-size: 0.63rem;
  font-style: normal;
  width: 100%;
  margin: 0 1rem;
  color: #646464;
  font-weight: 500;
`;

export const LoginSUOrText = styled.span`
  flex: 1.4;
  font-size: 0.63rem;
  font-style: normal;
  width: 100%;
  margin: 0 1rem;
  color: #646464;
  font-weight: 500;
  @media screen and (max-width: 960px) {
    margin: 0 0.5rem;
  }
`;

export const ReminberMeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const IAgreeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ForgotText = styled.span`
  display: flex;
  font-size: 1.1rem;
  font-style: normal;
  flex-direction: row;
  color: #f55653;
  align-items: flex-end;
  align-self: center;
  margin-left: auto;
  margin-top: 4px;
  flex-direction: column;
  cursor: pointer;
  font-weight: 700;
`;

export const PrivacyText = styled.span`
  display: flex;
  font-size: 0.8rem;
  font-style: normal;
  flex-direction: row;
  color: #f55653;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
  flex-direction: column;
  cursor: pointer;
  font-weight: 400;
`;

export const AndText = styled.span`
  display: flex;
  font-size: 0.8rem;
  font-style: normal;
  flex-direction: row;
  color: #8d9aae;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
  flex-direction: column;
  font-weight: 400;
`;

export const ConnexionButton = styled.button`
  width: 100%;
  height: 42px;
  border-radius: 7px;
  margin-top: 1.5rem;
  color: #fff;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
  background-color: #f55653;
`;

export const SignUpButton = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 7px;
  margin-top: 1.5rem;
  color: #fff;
  display: flex;
  font-size: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
  background-color: #f55653;
`;

export const SignuTextConatiner = styled.div`
  margin-top: 2.4rem;
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SignUpText = styled.span`
  display: flex;
  font-size: 1rem;
  font-style: normal;
  color: #f55653;
  flex-direction: column;
  cursor: pointer;
  font-weight: 700;
`;

export const SignUpDesc = styled.span`
  display: flex;
  font-size: 1.1rem;
  margin-right: 0.5rem;
  font-style: normal;
  flex-direction: row;
  color: #727682;
  font-weight: 700;
`;

export const SignUpContainer = styled.div`
  width: 80%;
  display: flex;
  height: 100%;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
  }
`;

export const SignUpLogoContainer = styled.div`
  display: flex;
  margin-top: 55px;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: 768px) {
    margin-top: 1.5rem;
  }
`;

export const SignUpLogoImg = styled.img`
  height: 75px;
  object-fit: cover;
`;

export const SignUpSIContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-left: auto;
  align-items: center;
`;

export const SignUpTexts = styled.span`
  display: flex;
  font-size: 1.1rem;
  font-style: normal;
  color: #f55653;
  flex-direction: column;
  cursor: pointer;
  font-weight: 700;
`;

export const SignUpDescs = styled.span`
  display: flex;
  font-size: 1rem;
  margin-right: 0.5rem;
  font-style: normal;
  flex-direction: row;
  color: #727682;
  font-weight: 700;
`;

export const SignUpMenberText = styled.span`
  font-size: 1.9rem;
  font-style: normal;
  width: 100%;
  color: #000 !important;
  margin-top: 2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  justify-content: center;
  align-self: center;
`;
export const BecomeMenberText = styled.span`
  font-size: 1.2rem;
  font-style: normal;
  color: white !important;
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

export const SignUpDescText = styled.span`
  font-size: 0.95rem;
  font-style: normal;
  width: 100%;
  color: #000 !important;
  font-weight: 500;
`;

export const SelectSubTypeContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 2rem 0;
  flex-direction: row;
`;

export const Login1Method = styled.div`
  flex: 1;
  margin-right: 0.5rem;
  height: 70px;
  display: flex;
  align-items: center;
  background-color: #48d26b;
  justify-content: center;
  cursor: pointer;
  flex-direction: row;
  border-radius: 4px;
`;

export const Login2Method = styled.div`
  flex: 1;
  margin-right: 0.5rem;
  height: 70px;
  display: flex;
  align-items: center;
  background-color: #f55653;
  justify-content: center;
  cursor: pointer;
  flex-direction: row;
  border-radius: 4px;
`;

export const Login3Method = styled.div`
  flex: 1;
  margin-right: 0.5rem;
  height: 70px;
  display: flex;
  align-items: center;
  background-color: #f9bd18;
  justify-content: center;
  cursor: pointer;
  flex-direction: row;
  border-radius: 4px;
`;

export const Login4Method = styled.div`
  flex: 1;
  height: 70px;
  display: flex;
  align-items: center;
  background-color: #4499ec;
  justify-content: center;
  cursor: pointer;
  flex-direction: row;
  border-radius: 4px;
`;

export const SelectMethodText = styled.span`
  font-size: 0.9rem;
  font-style: normal;
  color: #fff;
  font-weight: 700;
`;

export const LoginBarSUContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const FirstNameAndLastNamerCOntainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  flex-direction: row;
`;

export const RegisterSpinnerContainer = styled.span`
  margin-right: 0.5rem;
  flex-direction: column;
  display: none;
  justify-content: center;
`;

export const RegisterSpinner = styled(CgSpinner)`
  animation: spin 1s infinite linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
