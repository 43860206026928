import styled from "styled-components";

export const AddProductInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    margin-bottom: 0.5rem;
  }
`;

export const AddProductLabel = styled.label`
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  color: #000000;

  @media screen and (max-width: 768px) {
    font-weight: 400;
    font-size: 1.2rem;
  }
`;

export const AddProductInputs = styled.input`
  width: 100%;
  height: 50px;
  background: #ffffff;
  padding: 0 1rem;
  font-size: 0.9rem;
  outline: none;
  border: 1.5px solid #edeff3 !important;
  border-radius: 0.8rem;
  box-sizing: border-box;
`;

export const AddProductErrorMessage = styled.span`
  color: red;
  font-size: 0.8rem;
`;
