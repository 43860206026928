import styled from "styled-components";

export const Nav = styled.nav`
  display: none;
  @media screen and (max-width: 768px) {
    height: 5rem;
    display: flex;
    background-color: #2f3a4f;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 70px;
  overflow: hidden;
`;

export const NavLogo = styled.div`
  justify-self: flex-start;
  text-decoration: none;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 2rem;
  }
`;

export const MobileIcons = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const UserIcons = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 20px;
    left: 1.5rem;
    font-size: 1.8rem;
  }
`;

export const Img = styled.img`
  margin-right: 0.5em;
  height: 1.5rem;
  vertical-align: middle;
  border: 0;
`;

export const NavMenu = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    top: 70px;
    z-index: 1000;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: #fff;
  }
`;

export const NavUserProfilSectionContainer = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    position: absolute;
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    height: 95%;
  }
`;

export const NavUserProfilImg = styled.img`
  width: 150px;
  height: 150px;
  margin: 0 auto;
  object-fit: cover;
  border-radius: 50%;
`;

export const NavUserProfileNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const NavUserProfilName = styled.div`
  font-size: 1.5rem;
  font-style: normal;
  color: #000 !important;
  margin-top: 1rem;
  font-weight: 700;
  margin-bottom: 0.7rem;
  justify-content: center;
  align-self: center;
`;

export const NavUserProfilEmail = styled.div`
  font-size: 1.2rem;
  font-style: normal;
  color: #646464;
  font-weight: 500;
`;

export const NavEspacer = styled.div`
  width: 100%;
  height: 2.5rem;
`;

export const NavUserProfilSubTitleContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const NavUserProfilSubTitleContainers = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1rem;
`;

export const NavUserProfilSubTitle = styled.div`
  flex: 1;
  display: flex;
  font-size: 1.2rem;
  font-style: normal;
  flex-direction: row;
  color: #727682;
  font-weight: 500;
`;

export const NavUserProfilSubTitles = styled.div`
  display: flex;
  font-size: 1.2rem;
  font-style: normal;
  margin-top: 1.5rem;
  flex-direction: row;
  color: white;
  font-weight: 500;
`;

export const NavUserProfilSubContent = styled.div`
  flex: 1;
  display: flex;
  font-size: 1.2rem;
  font-style: normal;
  flex-direction: row;
  color: #000;
  opacity: 0.8;
  font-weight: 700;
`;

export const NavUserProfilSubContents = styled.div`
  flex: 1;
  display: flex;
  font-size: 1.2rem;
  font-style: normal;
  flex-direction: row;
  color: #000;
  opacity: 0.8;
  font-weight: 700;
`;

export const NavSpacer = styled.div`
  width: 100%;
  height: 1.5rem;
`;

export const NavButtonLogout = styled.div`
  width: 100%;
  height: 42px;
  border-radius: 10px;
  color: #fff;
  font-size: 1rem;
  margin-top: 3.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
  background-color: #f55653;
`;
