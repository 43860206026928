import React from "react";
import { SubText, SubTextContainer, SubTextImg } from "./SubModelText.elment";

const SubModelText = ({ text }) => {
  const img = require("../images/circled.png");

  return (
    <SubTextContainer>
      <SubTextImg src={img} />
      <SubText>{text}</SubText>
    </SubTextContainer>
  );
};

export default SubModelText;
