import React from "react";
import {
  ProductDetailContainer,
  ProductDetailGlobalContainer,
  ProductDetailLeftContainer,
  ProductHeadLeftPathText,
  ProductImgContainer,
  ProductImgsContainer,
  ProductDetailImgContainer,
  ProductDetailImg,
  ProductDetailRightContainer,
  ProductDetailNameText,
  ProductDetailPriceText,
  ProductDetailText,
  ProductDetailAddCartContainers,
  ProductDetailQuantityContainer,
  ProductDetailQuantityOption,
  ProductDetailQuantyText,
  ProductContactWhatContainer,
  ProductDetailAddCartContainer,
  ProductSizeContainer,
  ProductSizesContentContainer,
  ProductDetailTexts,
  RelatedProductContainer,
  ProductVideoThumbailContainer,
  ProductDetailVideoSelect,
  ProductVideoPlayIcon,
  ProductDetailImgSelect,
} from "./ProductDetail.element";
import ReactImageMagnify from "react-image-magnify";
import AccordionProductDesc from "../AccordionProductDesc";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../stores/shopping-cart/cartSlice";
import { toast } from "react-hot-toast";
import ReactWhatsapp from "react-whatsapp";
import ProductSize from "../ProductSize";
import axios from "axios";
import ProductColor from "../ProductColor";
import { ProductGridContainer } from "./CountryProduct.element";
import Product from "../Product";
import { generateMeetingId } from "../../utils/generatemeetingId";
import Slider from "react-slick";

const ProductDetail = ({ productT, devise, products }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    padding: "20px",
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          initialSlide: 4,
          dots: true,
        },
      },
    ],
  };

  const [product, setProduct] = React.useState(productT);
  const [activeColor, setActiveColor] = React.useState(null);
  const [activeSize, setActiveSize] = React.useState(null);
  const [quantity, setQuantity] = React.useState(1);
  const [imgUrl, setImgUrl] = React.useState(product.images[0]);
  const [videoClick, setVideoClick] = React.useState(false);
  const [oderId, setOrderId] = React.useState(generateMeetingId());
  const path = `${product.category.replace(/\b\w/g, (c) =>
    c.toUpperCase()
  )} | ${product.productName.replace(/\b\w/g, (c) => c.toUpperCase())}`;

  const dispatch = useDispatch();

  const playIcons = require("../../images/play-button.png");

  const cartItems = useSelector((state) => state.cart.cartItems);

  const userLocale = navigator.language || "en-US";
  const currencyFormatter = new Intl.NumberFormat(userLocale, {
    style: "currency",
    currency: devise,
    currencyDisplay: "symbol",
  });

  const message = `Hello, I would like to place an order for the product "${product.productName}" Ref: "PO-${oderId}" found on the website www.sinogani.com. Could you please provide me with more information regarding the price, available quantity, and delivery terms? Thank you!`;

  React.useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function serviceMail(to, subject, body) {
    const emailData = {
      to: to,
      subject: subject,
      body: body,
    };

    axios
      .post(
        "https://send-mail-manager-1.onrender.com/api/service-email",
        emailData
      )

      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la requête:", error);
      });
  }

  const handleProductClickContact = (p) => {
    setOrderId(generateMeetingId());

    const emailAddress = "contact@sinogani.com";
    const subject = `PO-${oderId}`;

    const body = `Subject: Product Order Placed

        Product Image Url: ${product.images[0]},
        
        Product Name: ${product.productName}

        Product Price: ${currencyFormatter.format(quantity * product.price)}

        Product Quantity: ${quantity}

        Product Size Select: ${activeSize}

        Product Color Select: ${activeColor}

        Product Seller Email: ${product.sellerMail}

        Product Seller Contact: ${product.contact}
    `;

    serviceMail(emailAddress, subject, body);
  };

  const handleItemClick = (itemValue) => {
    setActiveColor(itemValue === activeColor ? null : itemValue);
  };

  const handleSizeClick = (itemValue) => {
    setActiveSize(itemValue === activeSize ? null : itemValue);
  };

  const handleIncrementQuantity = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrementQuantity = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  const handleVideoClick = () => {
    setVideoClick(true);
  };

  const insideCart = cartItems.some((p) => p.uid === product.uid);

  const handleAddToCart = () => {
    if (insideCart) {
      toast.success("Product already added to cart");
    } else {
      toast.success("Product add to cart!");
    }

    const { uid, productName, description, price, images, contact } = product;

    const image = images[0];

    const totalPrice = price;

    dispatch(
      addToCart({
        uid,
        productName,
        description,
        price,
        image,
        contact,
        totalPrice,
      })
    );
  };

  const handleChangeState = (data) => {
    setProduct(data);
    setImgUrl(data.images[0]);
  };

  return (
    <ProductDetailGlobalContainer>
      <ProductHeadLeftPathText>{path}</ProductHeadLeftPathText>

      <ProductDetailContainer>
        <ProductDetailLeftContainer>
          <ProductImgContainer>
            {!videoClick ? (
              window.innerWidth > 768 ? (
                <div
                  style={{
                    width: "400px",
                    height: "400px",
                    display: "flex",
                    margin: "auto",
                    zIndex: 50,
                  }}
                >
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Image description",
                        isFluidWidth: false,
                        src: imgUrl,
                        width: 300,
                        height: 300,
                      },
                      largeImage: {
                        src: imgUrl,
                        width: 1200,
                        height: 1800,
                      },
                      enlargedImageContainerStyle: { background: "#fff" },
                      enlargedImageContainerDimensions: {
                        width: "200%",
                        height: "200%",
                      },
                      style: {
                        maxWidth: "100%", // Assurez-vous que l'image reste dans les limites
                        maxHeight: "100%", // Assurez-vous que l'image reste dans les limites
                        objectFit: "contain", // S'assure que l'image n'est pas coupée
                      },
                    }}
                  />
                </div>
              ) : (
                <ProductDetailImgSelect src={imgUrl} loading="lazy" />
              )
            ) : (
              <ProductDetailVideoSelect src={product.videoUrl} controls />
            )}

            {product.images.length > 1 ? (
              <div className="slider-container">
                <Slider {...settings}>
                  {product.videoUrl && (
                    <ProductVideoThumbailContainer onClick={handleVideoClick}>
                      <ProductVideoPlayIcon src={playIcons} />
                      <ProductDetailImg src={product.images[0]} />
                    </ProductVideoThumbailContainer>
                  )}
                  {product.images.map((url, index) => (
                    <ProductDetailImgContainer
                      key={index}
                      onClick={() => {
                        setVideoClick(false);
                        setImgUrl(url);
                      }}
                    >
                      <ProductDetailImg src={url} loading="lazy" />
                    </ProductDetailImgContainer>
                  ))}
                </Slider>
              </div>
            ) : (
              <ProductImgsContainer />
            )}
          </ProductImgContainer>
        </ProductDetailLeftContainer>

        <ProductDetailRightContainer>
          <ProductDetailNameText>{product.productName}</ProductDetailNameText>

          <ProductDetailPriceText>
            {currencyFormatter.format(quantity * product.price)}
          </ProductDetailPriceText>

          <ProductDetailText>
            Explore Sinogani: your unique shopping haven, where style meets
            emotion with each purchase.
          </ProductDetailText>

          <ProductDetailAddCartContainers>
            <ProductDetailQuantityContainer>
              <ProductDetailQuantityOption onClick={handleDecrementQuantity}>
                -
              </ProductDetailQuantityOption>
              <ProductDetailQuantyText>{quantity}</ProductDetailQuantyText>
              <ProductDetailQuantityOption onClick={handleIncrementQuantity}>
                +
              </ProductDetailQuantityOption>
            </ProductDetailQuantityContainer>

            <ProductDetailAddCartContainer onClick={handleAddToCart}>
              add to cart
            </ProductDetailAddCartContainer>
          </ProductDetailAddCartContainers>

          {product.sizes.length > 0 && (
            <ProductSizeContainer>
              <ProductDetailTexts>Product Sizes</ProductDetailTexts>
              <ProductSizesContentContainer>
                {product.sizes.map((size, index) => (
                  <ProductSize
                    active={activeSize}
                    onClick={() => handleSizeClick(size)}
                    size={size}
                    key={index}
                  />
                ))}
              </ProductSizesContentContainer>
            </ProductSizeContainer>
          )}

          {product.colors.length > 0 && (
            <ProductSizeContainer>
              <ProductDetailTexts>Product Colors</ProductDetailTexts>
              <ProductSizesContentContainer>
                {product.colors.map((color, index) => (
                  <ProductColor
                    active={activeColor}
                    onClick={() => handleItemClick(color)}
                    bg={color}
                    key={index}
                  />
                ))}
              </ProductSizesContentContainer>
            </ProductSizeContainer>
          )}

          <AccordionProductDesc contentText={product.description} />

          <ProductContactWhatContainer onClick={handleProductClickContact}>
            <ReactWhatsapp
              number="+86 187 1770 9574"
              message={message}
              className="react__product__whatsapp"
            >
              Contact via whatsapp
            </ReactWhatsapp>
          </ProductContactWhatContainer>
        </ProductDetailRightContainer>
      </ProductDetailContainer>

      {products.length > 1 && (
        <RelatedProductContainer>
          <ProductDetailNameText>Related Product</ProductDetailNameText>

          <ProductGridContainer>
            {products
              .filter((item) => item.uid !== product.uid)
              .map((item, index) => (
                <Product
                  product={item}
                  changeState={handleChangeState}
                  key={index}
                  devise={devise}
                />
              ))}
          </ProductGridContainer>
        </RelatedProductContainer>
      )}
    </ProductDetailGlobalContainer>
  );
};

export default ProductDetail;
