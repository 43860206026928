import styled from "styled-components";
import { Link } from "react-router-dom";

export const CountryProductConatiner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f1f1f1;
`;

export const CountryProductTextContainer = styled.div`
  width: 100%;
  height: 230px;
  background-color: #2f3a4f;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CartLink = styled(Link)`
  display: flex;
  align-self: center;
  cursor: pointer;
  text-decoration: none;
`;

export const FavorisIcon = styled.img`
  width: 30px;
  height: 30px;
`;

export const CountryTabFeedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1184px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 100%;
    overflow: hidden;
  }
`;

export const ProductLoadingContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 20rem;
`;

export const ProductScreenContentContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 150px;
  margin-bottom: 230px;

  @media screen and (max-width: 768px) {
    margin-top: 1rem;
    margin-bottom: 5rem;
  }
`;

export const TabHeadTitle = styled.div`
  width: 100%;
  border-bottom: 1px solid #d9d9d9 !important;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  margin-bottom: 80px;

  &::-webkit-scrollbar {
    width: 0;
  }

  @media screen and (max-width: 768px) {
    width: 96%;
    margin: 0 auto 3rem auto;
  }
`;

export const TabTitleBtn = styled.button`
  padding: 13px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  margin-right: 80px;
  background-color: #fff;
  color: #000000;
`;

export const TabHeadCartContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    padding: 0 1rem;
  }
`;

export const TabSearchBar = styled.div`
  box-sizing: border-box;
  height: 50px;
  border: 1px solid #dbdbdb !important;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 46px;

  @media screen and (max-width: 768px) {
    width: 95%;
    margin: 0 auto 1.5rem auto;
  }
`;

export const TabSearchIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 45px;
  align-self: center;
  @media screen and (max-width: 768px) {
    margin-left: 2rem;
  }
`;

export const TabSearchInput = styled.input`
  width: 100%;
  height: 100%;
  margin-left: 19px;
  font-style: normal;
  font-weight: 400;
  outline: none !important;
  background-color: #f1f1f1;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;

export const CTText2 = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-top: 30px;
  width: 100%;
  margin-bottom: 40px;

  color: #000000;

  @media screen and (max-width: 960px) {
  }
`;

export const ProductGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 320px));
  grid-column-gap: 110px;
  grid-row-gap: 30px;
  margin-bottom: 150px;
  width: 100%;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 0.7rem;
    grid-column-gap: 1.2rem;
    width: 96%;
    margin: 0 auto 4rem auto;
  }
`;
