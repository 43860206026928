import styled from "styled-components";

export const CartGlobalContainer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CartListContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const CartListContainers = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;
  }
`;

export const CartProductInfoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CartFirstInfoContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  flex: 2.5;
  height: 200px;
  @media screen and (max-width: 768px) {
    flex: none;
  }
`;

export const CartInfoContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  flex: 1;
  overflow: hidden;
  height: 100%;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const CartInfoContainers = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    height: 100%;
  }
`;

export const CartDeleteBtnProduct = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 27.57px;
  @media screen and (max-width: 768px) {
    margin-right: 1rem;
    margin-left: 0.7rem;
  }
`;

export const CartProductImg = styled.img`
  max-width: 150px;
  max-height: 150px;
  padding: 0;
  background: #d9d9d9;
  object-fit: cover;
  margin-right: 41px;
  border: none;
  @media screen and (max-width: 768px) {
    width: 100px;
    max-height: 100px;
    margin-right: 1rem;
    object-fit: contain;
    background: #fff;
  }
`;

export const CartProductNameContainer = styled.div`
  max-height: calc(1 * 1.2em);
  overflow: hidden;
  width: 250px;
  @media screen and (max-width: 768px) {
    width: 200px;
  }
`;

export const CartProductName = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #000000;
`;

export const CartProductPrice = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #000000;
`;

export const CartproductQuantityContainer = styled.div`
  min-width: 90px;
  height: 40px;
  display: flex;
  flex-direction: row;
  border: 1px solid #000000 !important;
  border-radius: 100px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 90px;
    margin-top: 2rem;
  }
`;

export const CartQuantityOption = styled.div`
  flex: 1;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  user-select: none !important;
  line-height: 27px;
  text-transform: uppercase;
  color: #000000;
`;

export const CartQuantyText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  user-select: none;
  text-transform: uppercase;
  color: #000000;
`;

export const CartProductTotalPrice = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #000000;
  @media screen and (max-width: 768px) {
    margin-top: 0.5rem;
  }
`;

export const CartBottomDivider = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid #000 !important;
`;
