import React from "react";
import {
  AfrcaSpaceCountriesContainer,
  AfricaSpaceContainer,
  AfricaSpaceCountriesGridContainer,
  AfricaSpaceSearchContainer,
  AfricaSpaceSearchIcon,
  AfricaSpaceSearchInputs,
  AfricaSpaceText,
} from "./CountrySpace.element";
import { AfricaAllCountriesData, ChinaAllProvinceData } from "../../data/data";
import Country from "../Country";
import Footer from "../Footer";

const CountrySpace = ({ isAfrica }) => {
  const [filterVal, setFilterVal] = React.useState("");
  const icon = require("../../images/search.png");
  const [filtered, setFiltered] = React.useState([]);
  const [searchApiData, setSearchApiData] = React.useState([]);

  const section = isAfrica ? (
    <AfricaSpaceText>Africa Space</AfricaSpaceText>
  ) : (
    <AfricaSpaceText>China Space</AfricaSpaceText>
  );

  const handleFilter = (e) => {
    if (e.target.value === "") {
      setFiltered(searchApiData);
    } else {
      const filterResult = searchApiData.filter((item) =>
        item.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFiltered(filterResult);
    }

    setFilterVal(e.target.value);
  };

  const compareByName = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  React.useEffect(() => {
    if (isAfrica) {
      setFiltered(AfricaAllCountriesData);
      setSearchApiData(AfricaAllCountriesData);
    } else {
      setFiltered(ChinaAllProvinceData);
      setSearchApiData(ChinaAllProvinceData);
    }
  }, []);

  return (
    <>
      <AfricaSpaceContainer>
        <AfrcaSpaceCountriesContainer>
          <AfricaSpaceSearchContainer>
            <AfricaSpaceSearchIcon src={icon} alt="Search Icon" />
            <AfricaSpaceSearchInputs
              value={filterVal}
              placeholder="Search"
              onInput={(e) => handleFilter(e)}
            />
          </AfricaSpaceSearchContainer>

          {section}

          <AfricaSpaceCountriesGridContainer>
            {[...filtered].sort(compareByName).map((item, index) => (
              <Country
                key={index}
                flag={item.imgLogo}
                countryName={item.name}
                id={item.id}
                africa={isAfrica}
                devise={item.devise}
              />
            ))}
          </AfricaSpaceCountriesGridContainer>
        </AfrcaSpaceCountriesContainer>
      </AfricaSpaceContainer>

      <Footer />
    </>
  );
};

export default CountrySpace;
